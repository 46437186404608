








import { Vue, Component } from 'vue-property-decorator'

@Component({
  name: 'App',
})
export default class App extends Vue {
  toService() {
    const userId = this.$store.getters.userInfo
      ? this.$store.getters.userInfo.userid
      : ''
    const route: any = this.$route
    const pageName = this.$t(route.meta.i18n)
    const customerInfo = pageName + ',' + userId
    window.open(
      'https://support.qq.com/product/371942?customInfo=' + customerInfo
    )
  }
}
